import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Spinner, SIZE } from 'baseui/spinner'

import { useAlert, useLocalStorage } from '@lemonbrain/hooks'

import {
  useGetPersonsIfManagesQuery,
  GetNextPersonsIfManagesQuery,
  GetNextPersonsIfManagesDocument,
  useDeletePersonMutation,
} from '../../../gql/members/Person.generated'
import { useGetStatesNoPersonQuery } from '../../../gql/members/State.generated'
import MembersTableBusiness from './MembersTable.business'
import { RowDataT } from './MembersTable'
import getReactApolloCients from '../../../localReactApollo'

export default function MembersTableIfManagesData({
  goToPerson,
  searchQuery,
  associationFilter,
  workLevelFilter,
  sportsEducationFilter,
  stateFilter,
  stateTypeFilter,
  membershipFilter,
  selectedPerson,
  setSelectedPerson,
}: {
  goToPerson: (id: string | null) => void
  searchQuery: string
  associationFilter: string[] | null
  workLevelFilter: string[] | null
  sportsEducationFilter: string[] | null
  stateFilter: string | null
  stateTypeFilter: string | null
  membershipFilter: string | null
  selectedPerson: string[]
  setSelectedPerson: React.Dispatch<React.SetStateAction<string[]>>
}) {
  const intl = useIntl()
  const setAlert = useAlert()
  const [data, setData] = useState<RowDataT[]>([])
  const [nextCursor, setNextCursor] = useState<string | null | undefined>()
  const [loading, setLoading] = useState(false)
  const [pageSize, setPageSize] = useLocalStorage<number>('pageSize', 10)
  const [pageCount, setPageCount] = useState(1)
  const client = getReactApolloCients().getClient()
  const {
    data: dataGetPerson,
    loading: loadingGetPerson,
    error: errorGetPerson,
    fetchMore,
    refetch,
  } = useGetPersonsIfManagesQuery({
    variables: {
      cursor: {
        pageSize,
        filtered: getFilter(),
      },
    },
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })
  const {
    data: dataState,
    loading: loadingState,
    error: errorState,
  } = useGetStatesNoPersonQuery({
    variables: {
      cursor: {
        pageSize: 100,
      },
    },
    //@ts-ignore
    client,
  })
  const [
    deletePersonMutation,
    { loading: loadingDelete, error: errorDelete },
  ] = useDeletePersonMutation({
    //@ts-ignore
    client,
  })

  const onFetchMoreData = function () {
    if (nextCursor) {
      fetchMore({
        query: GetNextPersonsIfManagesDocument,
        variables: {
          cursor: {
            pageSize,
            filtered: getFilter(),
            nextCursor: nextCursor,
          },
        },
        // @ts-ignore
        updateQuery: (
          previousResult,
          { fetchMoreResult }: { fetchMoreResult: GetNextPersonsIfManagesQuery }
        ) => {
          if (!previousResult) {
            return {
              getNextPersonsIfManages: {
                nextCursor: fetchMoreResult.getNextPersonsIfManages.nextCursor,
                items: [...fetchMoreResult.getNextPersonsIfManages.items],
              },
            }
          }
          if (
            fetchMoreResult.getNextPersonsIfManages.items.length > 0 &&
            previousResult.getPersonsIfManages.items.filter((item) => {
              return item.id === fetchMoreResult.getNextPersonsIfManages.items[0].id
            }).length === 0
          ) {
            return {
              ...previousResult,
              getPersonsIfManages: {
                ...previousResult.getPersonsIfManages,
                nextCursor: fetchMoreResult.getNextPersonsIfManages.nextCursor,
                items: [
                  ...previousResult.getPersonsIfManages.items,
                  ...fetchMoreResult.getNextPersonsIfManages.items,
                ],
              },
            }
          } else {
            return previousResult
          }
        },
      })
    }
  }
  const onRefetchData = function () {
    setPageCount(1)
    refetch({
      cursor: {
        pageSize,
        filtered: getFilter(),
      },
    })
  }

  function getFilter() {
    const filter: { id: string; value: string }[] = []

    filter.push({
      id: 'query',
      value: searchQuery,
    })

    if (associationFilter) {
      filter.push({
        id: 'association',
        value: associationFilter.join(','),
      })
    }

    if (workLevelFilter) {
      filter.push({
        id: 'workLevel',
        value: workLevelFilter.join(','),
      })
    }

    if (sportsEducationFilter) {
      filter.push({
        id: 'sportsEducation',
        value: sportsEducationFilter.join(','),
      })
    }

    if (stateFilter) {
      filter.push({
        id: 'state',
        value: stateFilter,
      })
    }

    if (stateTypeFilter) {
      filter.push({
        id: 'stateType',
        value: stateTypeFilter,
      })
    }

    if (membershipFilter) {
      filter.push({
        id: 'membership',
        value: membershipFilter,
      })
    }
    return filter
  }

  const deletePerson = async function (id: string) {
    await deletePersonMutation({
      variables: {
        id,
      },
    })
    await setData([])
    refetch()
  }

  useEffect(() => {
    setLoading(loadingGetPerson || loadingDelete)
  }, [loadingGetPerson, loadingDelete])

  useEffect(() => {
    if (errorGetPerson || errorDelete || errorState) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'members.membersTable.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'members.membersTable.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(
        errorGetPerson?.message ||
          '' + errorDelete?.message ||
          '' + errorState?.message
      )
    }
  }, [errorGetPerson, errorDelete, errorState])

  useEffect(() => {
    if (dataGetPerson && dataGetPerson?.getPersonsIfManages.items.length > 0) {
      setData(
        dataGetPerson.getPersonsIfManages.items.map((item) => ({
          id: item.id,
          selected: !!selectedPerson.find(item1 => item1 === item.id),
          email: item.email || '',
          firstName:
            item.addresses.find((item) => item.types[0].isInitType)
              ?.firstName || '',
          lastName:
            item.addresses.find((item) => item.types[0].isInitType)?.lastName ||
            '',
          firmName:
            item.addresses.find((item) => item.types[0].isInitType)?.firmName ||
            '',
          state: item.state || {
            id: '',
            name: {
              de: '',
              fr: '',
              it: '',
            },
          },
        }))
      )
      if (
        dataGetPerson.getPersonsIfManages.nextCursor &&
        dataGetPerson.getPersonsIfManages.nextCursor !== nextCursor
      ) {
        if (nextCursor !== dataGetPerson.getPersonsIfManages.nextCursor) {
          setPageCount((prev) => ++prev)
        }
        setNextCursor(dataGetPerson.getPersonsIfManages.nextCursor)
      }
    } else {
      setData([])
    }
  }, [dataGetPerson, selectedPerson])

  return loadingState ? (
    <Spinner $size={SIZE.large} />
  ) : (
    <MembersTableBusiness
      data={data}
      dataState={dataState}
      onFetchMoreData={onFetchMoreData}
      onRefetchData={onRefetchData}
      loading={loading}
      pageCount={pageCount}
      pageSize={pageSize}
      setPageSize={setPageSize}
      goToPerson={goToPerson}
      deletePerson={deletePerson}
      setSelectedPerson={setSelectedPerson}
    />
  )
}
