// @ts-nocheck
import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CourseFragmentFragment = (
  { __typename?: 'Course' }
  & Pick<Types.Course, 'id' | 'language' | 'contentCategory' | 'number' | 'price' | 'memberPrice' | 'teacher' | 'targetGroup' | 'date' | 'endDate' | 'time' | 'place' | 'subscribeTill' | 'minSubscribers' | 'maxSubscribers' | 'options' | 'status' | 'createdAt' | 'createdFrom' | 'updatedAt' | 'updatedFrom'>
  & { educationalStages: Array<Types.Maybe<(
    { __typename?: 'WorkLevel' }
    & Pick<Types.WorkLevel, 'id'>
    & { name: (
      { __typename?: 'TranslationObject' }
      & Pick<Types.TranslationObject, 'de' | 'fr' | 'it'>
    ) }
  )>>, title: (
    { __typename?: 'TranslationObject' }
    & Pick<Types.TranslationObject, 'de' | 'fr' | 'it'>
  ), description?: Types.Maybe<(
    { __typename?: 'TranslationObject' }
    & Pick<Types.TranslationObject, 'de' | 'fr' | 'it'>
  )>, special?: Types.Maybe<(
    { __typename?: 'TranslationObject' }
    & Pick<Types.TranslationObject, 'de' | 'fr' | 'it'>
  )>, courseRegistrations?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'CourseRegistration' }
    & Pick<Types.CourseRegistration, 'id' | 'createdAt' | 'signUpStatus' | 'comment' | 'finalPrice'>
    & { participant: (
      { __typename?: 'Person' }
      & Pick<Types.Person, 'id' | 'email'>
      & { addresses: Array<(
        { __typename?: 'Address' }
        & Pick<Types.Address, 'firstName' | 'lastName'>
      )> }
    ), invoices?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Types.Invoice, 'id' | 'invoiceNumber' | 'total' | 'payAmount' | 'payDate' | 'canceledAt'>
      & { mailing?: Types.Maybe<(
        { __typename?: 'Mailing' }
        & Pick<Types.Mailing, 'id' | 'sent' | 'failed' | 'state'>
      )>, reminderMailing?: Types.Maybe<(
        { __typename?: 'Mailing' }
        & Pick<Types.Mailing, 'id'>
      )> }
    )>>> }
  )>>> }
);

export type CourseRegistrationFragmentFragment = (
  { __typename?: 'CourseRegistration' }
  & Pick<Types.CourseRegistration, 'id' | 'createdAt' | 'signUpStatus' | 'comment' | 'finalPrice'>
  & { participant: (
    { __typename?: 'Person' }
    & Pick<Types.Person, 'id' | 'email'>
    & { addresses: Array<(
      { __typename?: 'Address' }
      & Pick<Types.Address, 'firstName' | 'lastName'>
    )> }
  ), invoices?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Types.Invoice, 'id' | 'invoiceNumber' | 'total' | 'payAmount' | 'payDate' | 'canceledAt'>
    & { mailing?: Types.Maybe<(
      { __typename?: 'Mailing' }
      & Pick<Types.Mailing, 'id' | 'sent' | 'failed' | 'state'>
    )> }
  )>>> }
);

export type CourseRegistrationExportFragmentFragment = (
  { __typename?: 'CourseRegistration' }
  & Pick<Types.CourseRegistration, 'id' | 'createdAt' | 'signUpStatus' | 'comment' | 'finalPrice'>
  & { participant: (
    { __typename?: 'Person' }
    & Pick<Types.Person, 'id' | 'email' | 'telephone' | 'mobile' | 'ahvNumber' | 'jsNumber' | 'birthdate'>
    & { state?: Types.Maybe<(
      { __typename?: 'State' }
      & Pick<Types.State, 'id'>
      & { name: (
        { __typename?: 'TranslationObject' }
        & Pick<Types.TranslationObject, 'de' | 'fr' | 'it'>
      ) }
    )>, addresses: Array<(
      { __typename?: 'Address' }
      & Pick<Types.Address, 'salutation' | 'firstName' | 'lastName' | 'streetNr' | 'postCode' | 'place'>
    )> }
  ), course: (
    { __typename?: 'Course' }
    & Pick<Types.Course, 'id' | 'number'>
    & { title: (
      { __typename?: 'TranslationObject' }
      & Pick<Types.TranslationObject, 'de' | 'fr' | 'it'>
    ) }
  ) }
);

export type GetCourseQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetCourseQuery = (
  { __typename?: 'Query' }
  & { getCourse: (
    { __typename?: 'Course' }
    & CourseFragmentFragment
  ) }
);

export type GetCoursesQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetCoursesQuery = (
  { __typename?: 'Query' }
  & { getCourses: (
    { __typename?: 'CoursePagination' }
    & Pick<Types.CoursePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Course' }
      & CourseFragmentFragment
    )> }
  ) }
);

export type GetNextCoursesQueryVariables = Types.Exact<{
  cursor: Types.NextCursorInput;
}>;


export type GetNextCoursesQuery = (
  { __typename?: 'Query' }
  & { getNextCourses: (
    { __typename?: 'CoursePagination' }
    & Pick<Types.CoursePagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Course' }
      & CourseFragmentFragment
    )> }
  ) }
);

export type GetCourseRegistrationsQueryVariables = Types.Exact<{
  cursor: Types.StartCursorInput;
}>;


export type GetCourseRegistrationsQuery = (
  { __typename?: 'Query' }
  & { getCourseRegistrations: (
    { __typename?: 'CourseRegistrationPagination' }
    & Pick<Types.CourseRegistrationPagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'CourseRegistration' }
      & CourseRegistrationExportFragmentFragment
    )> }
  ) }
);

export type GetNextCourseRegistrationsQueryVariables = Types.Exact<{
  cursor: Types.NextCursorInput;
}>;


export type GetNextCourseRegistrationsQuery = (
  { __typename?: 'Query' }
  & { getNextCourseRegistrations: (
    { __typename?: 'CourseRegistrationPagination' }
    & Pick<Types.CourseRegistrationPagination, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'CourseRegistration' }
      & CourseRegistrationExportFragmentFragment
    )> }
  ) }
);

export type CreateCourseMutationVariables = Types.Exact<{
  language?: Types.Maybe<Types.Scalars['String']>;
  educationalStageIds: Array<Types.Scalars['ID']>;
  contentCategory?: Types.Maybe<Types.Scalars['String']>;
  number?: Types.Maybe<Types.Scalars['Int']>;
  title?: Types.Maybe<Types.TranslationObjectUpdate>;
  description?: Types.Maybe<Types.TranslationObjectUpdate>;
  price?: Types.Maybe<Types.Scalars['Int']>;
  memberPrice?: Types.Maybe<Types.Scalars['Int']>;
  teacher?: Types.Maybe<Types.Scalars['String']>;
  targetGroup?: Types.Maybe<Types.Scalars['String']>;
  special?: Types.Maybe<Types.TranslationObjectUpdate>;
  date?: Types.Maybe<Types.Scalars['String']>;
  endDate?: Types.Maybe<Types.Scalars['String']>;
  time?: Types.Maybe<Types.Scalars['String']>;
  place?: Types.Maybe<Types.Scalars['String']>;
  subscribeTill?: Types.Maybe<Types.Scalars['String']>;
  minSubscribers?: Types.Maybe<Types.Scalars['Int']>;
  maxSubscribers?: Types.Maybe<Types.Scalars['Int']>;
  options: Array<Types.Scalars['String']>;
  status?: Types.Maybe<Types.CourseStatus>;
}>;


export type CreateCourseMutation = (
  { __typename?: 'Mutation' }
  & { createCourse: (
    { __typename?: 'Course' }
    & CourseFragmentFragment
  ) }
);

export type UpdateCourseMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  language?: Types.Maybe<Types.Scalars['String']>;
  educationalStageIds?: Types.Maybe<Array<Types.Scalars['ID']>>;
  contentCategory?: Types.Maybe<Types.Scalars['String']>;
  number?: Types.Maybe<Types.Scalars['Int']>;
  title?: Types.Maybe<Types.TranslationObjectUpdate>;
  description?: Types.Maybe<Types.TranslationObjectUpdate>;
  price?: Types.Maybe<Types.Scalars['Int']>;
  memberPrice?: Types.Maybe<Types.Scalars['Int']>;
  teacher?: Types.Maybe<Types.Scalars['String']>;
  targetGroup?: Types.Maybe<Types.Scalars['String']>;
  special?: Types.Maybe<Types.TranslationObjectUpdate>;
  date?: Types.Maybe<Types.Scalars['String']>;
  endDate?: Types.Maybe<Types.Scalars['String']>;
  time?: Types.Maybe<Types.Scalars['String']>;
  place?: Types.Maybe<Types.Scalars['String']>;
  subscribeTill?: Types.Maybe<Types.Scalars['String']>;
  minSubscribers?: Types.Maybe<Types.Scalars['Int']>;
  maxSubscribers?: Types.Maybe<Types.Scalars['Int']>;
  options?: Types.Maybe<Array<Types.Scalars['String']>>;
  status?: Types.Maybe<Types.CourseStatus>;
}>;


export type UpdateCourseMutation = (
  { __typename?: 'Mutation' }
  & { updateCourse: (
    { __typename?: 'Course' }
    & CourseFragmentFragment
  ) }
);

export type DeleteCourseMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteCourseMutation = (
  { __typename?: 'Mutation' }
  & { deleteCourse: (
    { __typename?: 'Course' }
    & CourseFragmentFragment
  ) }
);

export type ConfirmOpenRegistrationsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ConfirmOpenRegistrationsMutation = (
  { __typename?: 'Mutation' }
  & { confirmOpenRegistrations: (
    { __typename?: 'Course' }
    & CourseFragmentFragment
  ) }
);

export type CancelRegistrationsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type CancelRegistrationsMutation = (
  { __typename?: 'Mutation' }
  & { cancelRegistrations: (
    { __typename?: 'Course' }
    & CourseFragmentFragment
  ) }
);

export type ConfirmWaitingListMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ConfirmWaitingListMutation = (
  { __typename?: 'Mutation' }
  & { confirmWaitingList: (
    { __typename?: 'Course' }
    & CourseFragmentFragment
  ) }
);

export type ConfirmRegistrationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ConfirmRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { confirmRegistration: (
    { __typename?: 'CourseRegistration' }
    & CourseRegistrationFragmentFragment
  ) }
);

export type RegisterForCourseMutationVariables = Types.Exact<{
  courseId: Types.Scalars['ID'];
  participantId: Types.Scalars['ID'];
  signUpStatus: Types.SignUpState;
}>;


export type RegisterForCourseMutation = (
  { __typename?: 'Mutation' }
  & { registerForCourse: (
    { __typename?: 'Course' }
    & CourseFragmentFragment
  ) }
);

export type UnregisterFromCourseMutationVariables = Types.Exact<{
  courseId: Types.Scalars['ID'];
  participantId: Types.Scalars['ID'];
}>;


export type UnregisterFromCourseMutation = (
  { __typename?: 'Mutation' }
  & { unregisterFromCourse: (
    { __typename?: 'Course' }
    & CourseFragmentFragment
  ) }
);

export type UnregisterFromCourseByIdMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type UnregisterFromCourseByIdMutation = (
  { __typename?: 'Mutation' }
  & { unregisterFromCourseById: (
    { __typename?: 'Course' }
    & CourseFragmentFragment
  ) }
);

export type CreateAttendeesListMutationVariables = Types.Exact<{
  courseId: Types.Scalars['ID'];
}>;


export type CreateAttendeesListMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'createAttendeesList'>
);

export type SaveRegistrationCommentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  comment: Types.Scalars['String'];
}>;


export type SaveRegistrationCommentMutation = (
  { __typename?: 'Mutation' }
  & { saveRegistrationComment: (
    { __typename?: 'CourseRegistration' }
    & CourseRegistrationFragmentFragment
  ) }
);

export type SaveRegistrationFinalPriceMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  finalPrice: Types.Scalars['Int'];
}>;


export type SaveRegistrationFinalPriceMutation = (
  { __typename?: 'Mutation' }
  & { saveRegistrationFinalPrice: (
    { __typename?: 'CourseRegistration' }
    & CourseRegistrationFragmentFragment
  ) }
);

export const CourseFragmentFragmentDoc = gql`
    fragment CourseFragment on Course {
  id
  language
  educationalStages {
    id
    name {
      de
      fr
      it
    }
  }
  contentCategory
  number
  title {
    de
    fr
    it
  }
  description {
    de
    fr
    it
  }
  price
  memberPrice
  teacher
  targetGroup
  special {
    de
    fr
    it
  }
  date
  endDate
  time
  place
  subscribeTill
  minSubscribers
  maxSubscribers
  options
  status
  createdAt
  createdFrom
  updatedAt
  updatedFrom
  courseRegistrations {
    id
    createdAt
    participant {
      id
      email
      addresses {
        firstName
        lastName
      }
    }
    invoices {
      id
      invoiceNumber
      total
      payAmount
      payDate
      canceledAt
      mailing {
        id
        sent
        failed
        state
      }
      reminderMailing {
        id
      }
    }
    signUpStatus
    comment
    finalPrice
  }
}
    `;
export const CourseRegistrationFragmentFragmentDoc = gql`
    fragment CourseRegistrationFragment on CourseRegistration {
  id
  createdAt
  participant {
    id
    email
    addresses {
      firstName
      lastName
    }
  }
  invoices {
    id
    invoiceNumber
    total
    payAmount
    payDate
    canceledAt
    mailing {
      id
      sent
      failed
      state
    }
  }
  signUpStatus
  comment
  finalPrice
}
    `;
export const CourseRegistrationExportFragmentFragmentDoc = gql`
    fragment CourseRegistrationExportFragment on CourseRegistration {
  id
  createdAt
  participant {
    id
    email
    telephone
    mobile
    ahvNumber
    jsNumber
    birthdate
    state {
      id
      name {
        de
        fr
        it
      }
    }
    addresses {
      salutation
      firstName
      lastName
      streetNr
      postCode
      place
    }
  }
  course {
    id
    number
    title {
      de
      fr
      it
    }
  }
  signUpStatus
  comment
  finalPrice
}
    `;
export const GetCourseDocument = gql`
    query getCourse($id: ID!) {
  getCourse(id: $id) {
    ...CourseFragment
  }
}
    ${CourseFragmentFragmentDoc}`;

/**
 * __useGetCourseQuery__
 *
 * To run a query within a React component, call `useGetCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseQuery(baseOptions: Apollo.QueryHookOptions<GetCourseQuery, GetCourseQueryVariables>) {
        return Apollo.useQuery<GetCourseQuery, GetCourseQueryVariables>(GetCourseDocument, baseOptions);
      }
export function useGetCourseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourseQuery, GetCourseQueryVariables>) {
          return Apollo.useLazyQuery<GetCourseQuery, GetCourseQueryVariables>(GetCourseDocument, baseOptions);
        }
export type GetCourseQueryHookResult = ReturnType<typeof useGetCourseQuery>;
export type GetCourseLazyQueryHookResult = ReturnType<typeof useGetCourseLazyQuery>;
export type GetCourseQueryResult = Apollo.QueryResult<GetCourseQuery, GetCourseQueryVariables>;
export const GetCoursesDocument = gql`
    query getCourses($cursor: StartCursorInput!) {
  getCourses(cursor: $cursor) {
    items {
      ...CourseFragment
    }
    nextCursor
  }
}
    ${CourseFragmentFragmentDoc}`;

/**
 * __useGetCoursesQuery__
 *
 * To run a query within a React component, call `useGetCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetCoursesQuery(baseOptions: Apollo.QueryHookOptions<GetCoursesQuery, GetCoursesQueryVariables>) {
        return Apollo.useQuery<GetCoursesQuery, GetCoursesQueryVariables>(GetCoursesDocument, baseOptions);
      }
export function useGetCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoursesQuery, GetCoursesQueryVariables>) {
          return Apollo.useLazyQuery<GetCoursesQuery, GetCoursesQueryVariables>(GetCoursesDocument, baseOptions);
        }
export type GetCoursesQueryHookResult = ReturnType<typeof useGetCoursesQuery>;
export type GetCoursesLazyQueryHookResult = ReturnType<typeof useGetCoursesLazyQuery>;
export type GetCoursesQueryResult = Apollo.QueryResult<GetCoursesQuery, GetCoursesQueryVariables>;
export const GetNextCoursesDocument = gql`
    query getNextCourses($cursor: NextCursorInput!) {
  getNextCourses(cursor: $cursor) {
    items {
      ...CourseFragment
    }
    nextCursor
  }
}
    ${CourseFragmentFragmentDoc}`;

/**
 * __useGetNextCoursesQuery__
 *
 * To run a query within a React component, call `useGetNextCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextCoursesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNextCoursesQuery(baseOptions: Apollo.QueryHookOptions<GetNextCoursesQuery, GetNextCoursesQueryVariables>) {
        return Apollo.useQuery<GetNextCoursesQuery, GetNextCoursesQueryVariables>(GetNextCoursesDocument, baseOptions);
      }
export function useGetNextCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextCoursesQuery, GetNextCoursesQueryVariables>) {
          return Apollo.useLazyQuery<GetNextCoursesQuery, GetNextCoursesQueryVariables>(GetNextCoursesDocument, baseOptions);
        }
export type GetNextCoursesQueryHookResult = ReturnType<typeof useGetNextCoursesQuery>;
export type GetNextCoursesLazyQueryHookResult = ReturnType<typeof useGetNextCoursesLazyQuery>;
export type GetNextCoursesQueryResult = Apollo.QueryResult<GetNextCoursesQuery, GetNextCoursesQueryVariables>;
export const GetCourseRegistrationsDocument = gql`
    query getCourseRegistrations($cursor: StartCursorInput!) {
  getCourseRegistrations(cursor: $cursor) {
    items {
      ...CourseRegistrationExportFragment
    }
    nextCursor
  }
}
    ${CourseRegistrationExportFragmentFragmentDoc}`;

/**
 * __useGetCourseRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetCourseRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseRegistrationsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetCourseRegistrationsQuery(baseOptions: Apollo.QueryHookOptions<GetCourseRegistrationsQuery, GetCourseRegistrationsQueryVariables>) {
        return Apollo.useQuery<GetCourseRegistrationsQuery, GetCourseRegistrationsQueryVariables>(GetCourseRegistrationsDocument, baseOptions);
      }
export function useGetCourseRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourseRegistrationsQuery, GetCourseRegistrationsQueryVariables>) {
          return Apollo.useLazyQuery<GetCourseRegistrationsQuery, GetCourseRegistrationsQueryVariables>(GetCourseRegistrationsDocument, baseOptions);
        }
export type GetCourseRegistrationsQueryHookResult = ReturnType<typeof useGetCourseRegistrationsQuery>;
export type GetCourseRegistrationsLazyQueryHookResult = ReturnType<typeof useGetCourseRegistrationsLazyQuery>;
export type GetCourseRegistrationsQueryResult = Apollo.QueryResult<GetCourseRegistrationsQuery, GetCourseRegistrationsQueryVariables>;
export const GetNextCourseRegistrationsDocument = gql`
    query getNextCourseRegistrations($cursor: NextCursorInput!) {
  getNextCourseRegistrations(cursor: $cursor) {
    items {
      ...CourseRegistrationExportFragment
    }
    nextCursor
  }
}
    ${CourseRegistrationExportFragmentFragmentDoc}`;

/**
 * __useGetNextCourseRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetNextCourseRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextCourseRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextCourseRegistrationsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNextCourseRegistrationsQuery(baseOptions: Apollo.QueryHookOptions<GetNextCourseRegistrationsQuery, GetNextCourseRegistrationsQueryVariables>) {
        return Apollo.useQuery<GetNextCourseRegistrationsQuery, GetNextCourseRegistrationsQueryVariables>(GetNextCourseRegistrationsDocument, baseOptions);
      }
export function useGetNextCourseRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextCourseRegistrationsQuery, GetNextCourseRegistrationsQueryVariables>) {
          return Apollo.useLazyQuery<GetNextCourseRegistrationsQuery, GetNextCourseRegistrationsQueryVariables>(GetNextCourseRegistrationsDocument, baseOptions);
        }
export type GetNextCourseRegistrationsQueryHookResult = ReturnType<typeof useGetNextCourseRegistrationsQuery>;
export type GetNextCourseRegistrationsLazyQueryHookResult = ReturnType<typeof useGetNextCourseRegistrationsLazyQuery>;
export type GetNextCourseRegistrationsQueryResult = Apollo.QueryResult<GetNextCourseRegistrationsQuery, GetNextCourseRegistrationsQueryVariables>;
export const CreateCourseDocument = gql`
    mutation createCourse($language: String, $educationalStageIds: [ID!]!, $contentCategory: String, $number: Int, $title: TranslationObjectUpdate, $description: TranslationObjectUpdate, $price: Int, $memberPrice: Int, $teacher: String, $targetGroup: String, $special: TranslationObjectUpdate, $date: String, $endDate: String, $time: String, $place: String, $subscribeTill: String, $minSubscribers: Int, $maxSubscribers: Int, $options: [String!]!, $status: CourseStatus) {
  createCourse(
    language: $language
    educationalStageIds: $educationalStageIds
    contentCategory: $contentCategory
    number: $number
    title: $title
    description: $description
    price: $price
    memberPrice: $memberPrice
    teacher: $teacher
    targetGroup: $targetGroup
    special: $special
    date: $date
    endDate: $endDate
    time: $time
    place: $place
    subscribeTill: $subscribeTill
    minSubscribers: $minSubscribers
    maxSubscribers: $maxSubscribers
    options: $options
    status: $status
  ) {
    ...CourseFragment
  }
}
    ${CourseFragmentFragmentDoc}`;
export type CreateCourseMutationFn = Apollo.MutationFunction<CreateCourseMutation, CreateCourseMutationVariables>;

/**
 * __useCreateCourseMutation__
 *
 * To run a mutation, you first call `useCreateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseMutation, { data, loading, error }] = useCreateCourseMutation({
 *   variables: {
 *      language: // value for 'language'
 *      educationalStageIds: // value for 'educationalStageIds'
 *      contentCategory: // value for 'contentCategory'
 *      number: // value for 'number'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      price: // value for 'price'
 *      memberPrice: // value for 'memberPrice'
 *      teacher: // value for 'teacher'
 *      targetGroup: // value for 'targetGroup'
 *      special: // value for 'special'
 *      date: // value for 'date'
 *      endDate: // value for 'endDate'
 *      time: // value for 'time'
 *      place: // value for 'place'
 *      subscribeTill: // value for 'subscribeTill'
 *      minSubscribers: // value for 'minSubscribers'
 *      maxSubscribers: // value for 'maxSubscribers'
 *      options: // value for 'options'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useCreateCourseMutation(baseOptions?: Apollo.MutationHookOptions<CreateCourseMutation, CreateCourseMutationVariables>) {
        return Apollo.useMutation<CreateCourseMutation, CreateCourseMutationVariables>(CreateCourseDocument, baseOptions);
      }
export type CreateCourseMutationHookResult = ReturnType<typeof useCreateCourseMutation>;
export type CreateCourseMutationResult = Apollo.MutationResult<CreateCourseMutation>;
export type CreateCourseMutationOptions = Apollo.BaseMutationOptions<CreateCourseMutation, CreateCourseMutationVariables>;
export const UpdateCourseDocument = gql`
    mutation updateCourse($id: ID!, $language: String, $educationalStageIds: [ID!], $contentCategory: String, $number: Int, $title: TranslationObjectUpdate, $description: TranslationObjectUpdate, $price: Int, $memberPrice: Int, $teacher: String, $targetGroup: String, $special: TranslationObjectUpdate, $date: String, $endDate: String, $time: String, $place: String, $subscribeTill: String, $minSubscribers: Int, $maxSubscribers: Int, $options: [String!], $status: CourseStatus) {
  updateCourse(
    id: $id
    language: $language
    educationalStageIds: $educationalStageIds
    contentCategory: $contentCategory
    number: $number
    title: $title
    description: $description
    price: $price
    memberPrice: $memberPrice
    teacher: $teacher
    targetGroup: $targetGroup
    special: $special
    date: $date
    endDate: $endDate
    time: $time
    place: $place
    subscribeTill: $subscribeTill
    minSubscribers: $minSubscribers
    maxSubscribers: $maxSubscribers
    options: $options
    status: $status
  ) {
    ...CourseFragment
  }
}
    ${CourseFragmentFragmentDoc}`;
export type UpdateCourseMutationFn = Apollo.MutationFunction<UpdateCourseMutation, UpdateCourseMutationVariables>;

/**
 * __useUpdateCourseMutation__
 *
 * To run a mutation, you first call `useUpdateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseMutation, { data, loading, error }] = useUpdateCourseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *      educationalStageIds: // value for 'educationalStageIds'
 *      contentCategory: // value for 'contentCategory'
 *      number: // value for 'number'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      price: // value for 'price'
 *      memberPrice: // value for 'memberPrice'
 *      teacher: // value for 'teacher'
 *      targetGroup: // value for 'targetGroup'
 *      special: // value for 'special'
 *      date: // value for 'date'
 *      endDate: // value for 'endDate'
 *      time: // value for 'time'
 *      place: // value for 'place'
 *      subscribeTill: // value for 'subscribeTill'
 *      minSubscribers: // value for 'minSubscribers'
 *      maxSubscribers: // value for 'maxSubscribers'
 *      options: // value for 'options'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateCourseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCourseMutation, UpdateCourseMutationVariables>) {
        return Apollo.useMutation<UpdateCourseMutation, UpdateCourseMutationVariables>(UpdateCourseDocument, baseOptions);
      }
export type UpdateCourseMutationHookResult = ReturnType<typeof useUpdateCourseMutation>;
export type UpdateCourseMutationResult = Apollo.MutationResult<UpdateCourseMutation>;
export type UpdateCourseMutationOptions = Apollo.BaseMutationOptions<UpdateCourseMutation, UpdateCourseMutationVariables>;
export const DeleteCourseDocument = gql`
    mutation deleteCourse($id: ID!) {
  deleteCourse(id: $id) {
    ...CourseFragment
  }
}
    ${CourseFragmentFragmentDoc}`;
export type DeleteCourseMutationFn = Apollo.MutationFunction<DeleteCourseMutation, DeleteCourseMutationVariables>;

/**
 * __useDeleteCourseMutation__
 *
 * To run a mutation, you first call `useDeleteCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseMutation, { data, loading, error }] = useDeleteCourseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCourseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCourseMutation, DeleteCourseMutationVariables>) {
        return Apollo.useMutation<DeleteCourseMutation, DeleteCourseMutationVariables>(DeleteCourseDocument, baseOptions);
      }
export type DeleteCourseMutationHookResult = ReturnType<typeof useDeleteCourseMutation>;
export type DeleteCourseMutationResult = Apollo.MutationResult<DeleteCourseMutation>;
export type DeleteCourseMutationOptions = Apollo.BaseMutationOptions<DeleteCourseMutation, DeleteCourseMutationVariables>;
export const ConfirmOpenRegistrationsDocument = gql`
    mutation confirmOpenRegistrations($id: ID!) {
  confirmOpenRegistrations(id: $id) {
    ...CourseFragment
  }
}
    ${CourseFragmentFragmentDoc}`;
export type ConfirmOpenRegistrationsMutationFn = Apollo.MutationFunction<ConfirmOpenRegistrationsMutation, ConfirmOpenRegistrationsMutationVariables>;

/**
 * __useConfirmOpenRegistrationsMutation__
 *
 * To run a mutation, you first call `useConfirmOpenRegistrationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmOpenRegistrationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmOpenRegistrationsMutation, { data, loading, error }] = useConfirmOpenRegistrationsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfirmOpenRegistrationsMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmOpenRegistrationsMutation, ConfirmOpenRegistrationsMutationVariables>) {
        return Apollo.useMutation<ConfirmOpenRegistrationsMutation, ConfirmOpenRegistrationsMutationVariables>(ConfirmOpenRegistrationsDocument, baseOptions);
      }
export type ConfirmOpenRegistrationsMutationHookResult = ReturnType<typeof useConfirmOpenRegistrationsMutation>;
export type ConfirmOpenRegistrationsMutationResult = Apollo.MutationResult<ConfirmOpenRegistrationsMutation>;
export type ConfirmOpenRegistrationsMutationOptions = Apollo.BaseMutationOptions<ConfirmOpenRegistrationsMutation, ConfirmOpenRegistrationsMutationVariables>;
export const CancelRegistrationsDocument = gql`
    mutation cancelRegistrations($id: ID!) {
  cancelRegistrations(id: $id) {
    ...CourseFragment
  }
}
    ${CourseFragmentFragmentDoc}`;
export type CancelRegistrationsMutationFn = Apollo.MutationFunction<CancelRegistrationsMutation, CancelRegistrationsMutationVariables>;

/**
 * __useCancelRegistrationsMutation__
 *
 * To run a mutation, you first call `useCancelRegistrationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRegistrationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRegistrationsMutation, { data, loading, error }] = useCancelRegistrationsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelRegistrationsMutation(baseOptions?: Apollo.MutationHookOptions<CancelRegistrationsMutation, CancelRegistrationsMutationVariables>) {
        return Apollo.useMutation<CancelRegistrationsMutation, CancelRegistrationsMutationVariables>(CancelRegistrationsDocument, baseOptions);
      }
export type CancelRegistrationsMutationHookResult = ReturnType<typeof useCancelRegistrationsMutation>;
export type CancelRegistrationsMutationResult = Apollo.MutationResult<CancelRegistrationsMutation>;
export type CancelRegistrationsMutationOptions = Apollo.BaseMutationOptions<CancelRegistrationsMutation, CancelRegistrationsMutationVariables>;
export const ConfirmWaitingListDocument = gql`
    mutation confirmWaitingList($id: ID!) {
  confirmWaitingList(id: $id) {
    ...CourseFragment
  }
}
    ${CourseFragmentFragmentDoc}`;
export type ConfirmWaitingListMutationFn = Apollo.MutationFunction<ConfirmWaitingListMutation, ConfirmWaitingListMutationVariables>;

/**
 * __useConfirmWaitingListMutation__
 *
 * To run a mutation, you first call `useConfirmWaitingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmWaitingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmWaitingListMutation, { data, loading, error }] = useConfirmWaitingListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfirmWaitingListMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmWaitingListMutation, ConfirmWaitingListMutationVariables>) {
        return Apollo.useMutation<ConfirmWaitingListMutation, ConfirmWaitingListMutationVariables>(ConfirmWaitingListDocument, baseOptions);
      }
export type ConfirmWaitingListMutationHookResult = ReturnType<typeof useConfirmWaitingListMutation>;
export type ConfirmWaitingListMutationResult = Apollo.MutationResult<ConfirmWaitingListMutation>;
export type ConfirmWaitingListMutationOptions = Apollo.BaseMutationOptions<ConfirmWaitingListMutation, ConfirmWaitingListMutationVariables>;
export const ConfirmRegistrationDocument = gql`
    mutation confirmRegistration($id: ID!) {
  confirmRegistration(id: $id) {
    ...CourseRegistrationFragment
  }
}
    ${CourseRegistrationFragmentFragmentDoc}`;
export type ConfirmRegistrationMutationFn = Apollo.MutationFunction<ConfirmRegistrationMutation, ConfirmRegistrationMutationVariables>;

/**
 * __useConfirmRegistrationMutation__
 *
 * To run a mutation, you first call `useConfirmRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmRegistrationMutation, { data, loading, error }] = useConfirmRegistrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfirmRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmRegistrationMutation, ConfirmRegistrationMutationVariables>) {
        return Apollo.useMutation<ConfirmRegistrationMutation, ConfirmRegistrationMutationVariables>(ConfirmRegistrationDocument, baseOptions);
      }
export type ConfirmRegistrationMutationHookResult = ReturnType<typeof useConfirmRegistrationMutation>;
export type ConfirmRegistrationMutationResult = Apollo.MutationResult<ConfirmRegistrationMutation>;
export type ConfirmRegistrationMutationOptions = Apollo.BaseMutationOptions<ConfirmRegistrationMutation, ConfirmRegistrationMutationVariables>;
export const RegisterForCourseDocument = gql`
    mutation registerForCourse($courseId: ID!, $participantId: ID!, $signUpStatus: SignUpState!) {
  registerForCourse(
    courseId: $courseId
    participantId: $participantId
    signUpStatus: $signUpStatus
  ) {
    ...CourseFragment
  }
}
    ${CourseFragmentFragmentDoc}`;
export type RegisterForCourseMutationFn = Apollo.MutationFunction<RegisterForCourseMutation, RegisterForCourseMutationVariables>;

/**
 * __useRegisterForCourseMutation__
 *
 * To run a mutation, you first call `useRegisterForCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterForCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerForCourseMutation, { data, loading, error }] = useRegisterForCourseMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      participantId: // value for 'participantId'
 *      signUpStatus: // value for 'signUpStatus'
 *   },
 * });
 */
export function useRegisterForCourseMutation(baseOptions?: Apollo.MutationHookOptions<RegisterForCourseMutation, RegisterForCourseMutationVariables>) {
        return Apollo.useMutation<RegisterForCourseMutation, RegisterForCourseMutationVariables>(RegisterForCourseDocument, baseOptions);
      }
export type RegisterForCourseMutationHookResult = ReturnType<typeof useRegisterForCourseMutation>;
export type RegisterForCourseMutationResult = Apollo.MutationResult<RegisterForCourseMutation>;
export type RegisterForCourseMutationOptions = Apollo.BaseMutationOptions<RegisterForCourseMutation, RegisterForCourseMutationVariables>;
export const UnregisterFromCourseDocument = gql`
    mutation unregisterFromCourse($courseId: ID!, $participantId: ID!) {
  unregisterFromCourse(courseId: $courseId, participantId: $participantId) {
    ...CourseFragment
  }
}
    ${CourseFragmentFragmentDoc}`;
export type UnregisterFromCourseMutationFn = Apollo.MutationFunction<UnregisterFromCourseMutation, UnregisterFromCourseMutationVariables>;

/**
 * __useUnregisterFromCourseMutation__
 *
 * To run a mutation, you first call `useUnregisterFromCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnregisterFromCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unregisterFromCourseMutation, { data, loading, error }] = useUnregisterFromCourseMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useUnregisterFromCourseMutation(baseOptions?: Apollo.MutationHookOptions<UnregisterFromCourseMutation, UnregisterFromCourseMutationVariables>) {
        return Apollo.useMutation<UnregisterFromCourseMutation, UnregisterFromCourseMutationVariables>(UnregisterFromCourseDocument, baseOptions);
      }
export type UnregisterFromCourseMutationHookResult = ReturnType<typeof useUnregisterFromCourseMutation>;
export type UnregisterFromCourseMutationResult = Apollo.MutationResult<UnregisterFromCourseMutation>;
export type UnregisterFromCourseMutationOptions = Apollo.BaseMutationOptions<UnregisterFromCourseMutation, UnregisterFromCourseMutationVariables>;
export const UnregisterFromCourseByIdDocument = gql`
    mutation unregisterFromCourseById($id: ID!) {
  unregisterFromCourseById(id: $id) {
    ...CourseFragment
  }
}
    ${CourseFragmentFragmentDoc}`;
export type UnregisterFromCourseByIdMutationFn = Apollo.MutationFunction<UnregisterFromCourseByIdMutation, UnregisterFromCourseByIdMutationVariables>;

/**
 * __useUnregisterFromCourseByIdMutation__
 *
 * To run a mutation, you first call `useUnregisterFromCourseByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnregisterFromCourseByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unregisterFromCourseByIdMutation, { data, loading, error }] = useUnregisterFromCourseByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnregisterFromCourseByIdMutation(baseOptions?: Apollo.MutationHookOptions<UnregisterFromCourseByIdMutation, UnregisterFromCourseByIdMutationVariables>) {
        return Apollo.useMutation<UnregisterFromCourseByIdMutation, UnregisterFromCourseByIdMutationVariables>(UnregisterFromCourseByIdDocument, baseOptions);
      }
export type UnregisterFromCourseByIdMutationHookResult = ReturnType<typeof useUnregisterFromCourseByIdMutation>;
export type UnregisterFromCourseByIdMutationResult = Apollo.MutationResult<UnregisterFromCourseByIdMutation>;
export type UnregisterFromCourseByIdMutationOptions = Apollo.BaseMutationOptions<UnregisterFromCourseByIdMutation, UnregisterFromCourseByIdMutationVariables>;
export const CreateAttendeesListDocument = gql`
    mutation createAttendeesList($courseId: ID!) {
  createAttendeesList(courseId: $courseId)
}
    `;
export type CreateAttendeesListMutationFn = Apollo.MutationFunction<CreateAttendeesListMutation, CreateAttendeesListMutationVariables>;

/**
 * __useCreateAttendeesListMutation__
 *
 * To run a mutation, you first call `useCreateAttendeesListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttendeesListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttendeesListMutation, { data, loading, error }] = useCreateAttendeesListMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useCreateAttendeesListMutation(baseOptions?: Apollo.MutationHookOptions<CreateAttendeesListMutation, CreateAttendeesListMutationVariables>) {
        return Apollo.useMutation<CreateAttendeesListMutation, CreateAttendeesListMutationVariables>(CreateAttendeesListDocument, baseOptions);
      }
export type CreateAttendeesListMutationHookResult = ReturnType<typeof useCreateAttendeesListMutation>;
export type CreateAttendeesListMutationResult = Apollo.MutationResult<CreateAttendeesListMutation>;
export type CreateAttendeesListMutationOptions = Apollo.BaseMutationOptions<CreateAttendeesListMutation, CreateAttendeesListMutationVariables>;
export const SaveRegistrationCommentDocument = gql`
    mutation saveRegistrationComment($id: ID!, $comment: String!) {
  saveRegistrationComment(id: $id, comment: $comment) {
    ...CourseRegistrationFragment
  }
}
    ${CourseRegistrationFragmentFragmentDoc}`;
export type SaveRegistrationCommentMutationFn = Apollo.MutationFunction<SaveRegistrationCommentMutation, SaveRegistrationCommentMutationVariables>;

/**
 * __useSaveRegistrationCommentMutation__
 *
 * To run a mutation, you first call `useSaveRegistrationCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveRegistrationCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveRegistrationCommentMutation, { data, loading, error }] = useSaveRegistrationCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useSaveRegistrationCommentMutation(baseOptions?: Apollo.MutationHookOptions<SaveRegistrationCommentMutation, SaveRegistrationCommentMutationVariables>) {
        return Apollo.useMutation<SaveRegistrationCommentMutation, SaveRegistrationCommentMutationVariables>(SaveRegistrationCommentDocument, baseOptions);
      }
export type SaveRegistrationCommentMutationHookResult = ReturnType<typeof useSaveRegistrationCommentMutation>;
export type SaveRegistrationCommentMutationResult = Apollo.MutationResult<SaveRegistrationCommentMutation>;
export type SaveRegistrationCommentMutationOptions = Apollo.BaseMutationOptions<SaveRegistrationCommentMutation, SaveRegistrationCommentMutationVariables>;
export const SaveRegistrationFinalPriceDocument = gql`
    mutation saveRegistrationFinalPrice($id: ID!, $finalPrice: Int!) {
  saveRegistrationFinalPrice(id: $id, finalPrice: $finalPrice) {
    ...CourseRegistrationFragment
  }
}
    ${CourseRegistrationFragmentFragmentDoc}`;
export type SaveRegistrationFinalPriceMutationFn = Apollo.MutationFunction<SaveRegistrationFinalPriceMutation, SaveRegistrationFinalPriceMutationVariables>;

/**
 * __useSaveRegistrationFinalPriceMutation__
 *
 * To run a mutation, you first call `useSaveRegistrationFinalPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveRegistrationFinalPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveRegistrationFinalPriceMutation, { data, loading, error }] = useSaveRegistrationFinalPriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      finalPrice: // value for 'finalPrice'
 *   },
 * });
 */
export function useSaveRegistrationFinalPriceMutation(baseOptions?: Apollo.MutationHookOptions<SaveRegistrationFinalPriceMutation, SaveRegistrationFinalPriceMutationVariables>) {
        return Apollo.useMutation<SaveRegistrationFinalPriceMutation, SaveRegistrationFinalPriceMutationVariables>(SaveRegistrationFinalPriceDocument, baseOptions);
      }
export type SaveRegistrationFinalPriceMutationHookResult = ReturnType<typeof useSaveRegistrationFinalPriceMutation>;
export type SaveRegistrationFinalPriceMutationResult = Apollo.MutationResult<SaveRegistrationFinalPriceMutation>;
export type SaveRegistrationFinalPriceMutationOptions = Apollo.BaseMutationOptions<SaveRegistrationFinalPriceMutation, SaveRegistrationFinalPriceMutationVariables>;