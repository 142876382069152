import { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Spinner, SIZE } from 'baseui/spinner'

import { useAlert, useLocalStorage } from '@lemonbrain/hooks'

import {
  useGetCoursesQuery,
  GetNextCoursesQuery,
  GetNextCoursesDocument,
  useDeleteCourseMutation,
  CourseFragmentFragment,
  useConfirmOpenRegistrationsMutation,
  useCancelRegistrationsMutation,
  useUpdateCourseMutation,
} from '../../../gql/Courses.generated'

import { CourseStatus, SignUpState, MailingState } from '../../../types'
import CoursesTableBusiness from './CoursesTable.business'
import { RowDataT } from './CoursesTable'
import getReactApolloCients from '../../../localReactApollo'
import { getTranslation } from '@lemonbrain/utils'
import { Tag, KIND, VARIANT } from 'baseui/tag'
type courseReg = {
  unconfirmed: number
  waitingList: number
  confirmed: number
  payed: number
  cancelled: number
}

export default function CoursesTableData({
  goToCourse,
  sendMailing,
  personId,
}: {
  goToCourse: (id: string | null) => void
  sendMailing: (id: string) => void
  personId?: string
}) {
  const intl = useIntl()
  const setAlert = useAlert()
  const [data, setData] = useState<RowDataT[]>([])
  const [nextCursor, setNextCursor] = useState<string | null | undefined>()
  const [loading, setLoading] = useState(false)
  const [pageSize, setPageSize] = useLocalStorage<number>('pageSize', 10)
  const [pageCount, setPageCount] = useState(1)
  const client = getReactApolloCients().getClient()
  const {
    data: dataGetCourses,
    loading: loadingGetCourses,
    error: errorGetCourses,
    fetchMore,
    refetch,
  } = useGetCoursesQuery({
    variables: {
      cursor: {
        filtered: getFilter(),
        pageSize,
      },
    },
    fetchPolicy: 'network-only',
    //@ts-ignore
    client,
  })

  const [
    deleteCourseMutation,
    { loading: loadingDelete, error: errorDelete },
  ] = useDeleteCourseMutation({
    //@ts-ignore
    client,
  })

  const [
    updateCourseMutation,
  ] = useUpdateCourseMutation({
    //@ts-ignore
    client,
  })

  const [
    confirmOpenRegistrationsMutation,
  ] = useConfirmOpenRegistrationsMutation({
    //@ts-ignore
    client: client
  })

  const [
    cancelRegistrationsMutation,
  ] = useCancelRegistrationsMutation({
    //@ts-ignore
    client: client
  })

  const confirmOpenRegistrations = async function (id: string) {
    try {
      await confirmOpenRegistrationsMutation({
        variables: {id},
      })
    } catch (error) {
      console.error(error)
    }
  }

  const cancelRegistrations = async function (id: string) {
    try {
      await cancelRegistrationsMutation({
        variables: {id},
      })
    } catch (error) {
      console.error(error)
    }
  }

  const onFetchMoreData = function () {
    if (nextCursor) {
      fetchMore({
        query: GetNextCoursesDocument,
        variables: {
          cursor: {
            pageSize,
            filtered: getFilter(),
            nextCursor: nextCursor,
          },
        },
        // @ts-ignore
        updateQuery: (
          previousResult,
          { fetchMoreResult }: { fetchMoreResult: GetNextCoursesQuery }
        ) => {
          if (!previousResult) {
            return {
              getNextCourses: {
                nextCursor: fetchMoreResult.getNextCourses.nextCursor,
                items: [...fetchMoreResult.getNextCourses.items],
              },
            }
          }
          if (
            fetchMoreResult.getNextCourses.items.length > 0 &&
            previousResult.getCourses.items.filter((item) => {
              return item.id === fetchMoreResult.getNextCourses.items[0].id
            }).length === 0
          ) {
            return {
              ...previousResult,
              getCourses: {
                ...previousResult.getCourses,
                nextCursor: fetchMoreResult.getNextCourses.nextCursor,
                items: [
                  ...previousResult.getCourses.items,
                  ...fetchMoreResult.getNextCourses.items,
                ],
              },
            }
          } else {
            return previousResult
          }
        },
      })
    }
  }
  const onRefetchData = function () {
    setPageCount(1)
    refetch({
      cursor: {
        pageSize,
      },
    })
  }

  const deleteCourse = async function (id: string) {
    await deleteCourseMutation({
      variables: {
        id,
      },
    })
    setAlert &&
      setAlert({
        title: intl.formatMessage({
          id: 'courses.coursesTable.removeRow.success',
          defaultMessage: 'Entry removed',
        }),
        style: 'success',
      })
    await setData([])
    refetch()
  }

  const getMailables = function (course: CourseFragmentFragment) {
    let count = 0;
    course.courseRegistrations?.forEach((registration) => {
      if (registration?.signUpStatus === SignUpState.Confirmed) {
        registration.invoices?.forEach(invoice => {
          if (invoice?.mailing?.state === MailingState.Pending) {
            count++
          }
        })
      }
    })

    return count
  }

  const confirmCourse = async function (courseId: string) {
    await updateCourseMutation({
      variables: {
        id: courseId,
        status: CourseStatus.Confirmed,
      },
      //@ts-ignore
      client,
    })

    await confirmOpenRegistrations(courseId)

    onRefetchData()
  }

  const cancelCourse = async function (courseId: string) {
    await updateCourseMutation({
      variables: {
        id: courseId,
        status: CourseStatus.Cancelled,
      },
      //@ts-ignore
      client,
    })

    await cancelRegistrations(courseId)

    onRefetchData()
  }

  const getCourseStatus = function (course: CourseFragmentFragment) {
    let courseRegistrations = {
      unconfirmed: 0,
      waitingList: 0,
      confirmed: 0,
      payed: 0,
      cancelled: 0,
    }
    course.courseRegistrations?.forEach((registration) => {
      switch (registration?.signUpStatus) {
        case SignUpState.Unconfirmed:
          courseRegistrations[SignUpState.Unconfirmed]++
          break;
        case SignUpState.WaitingList:
          courseRegistrations[SignUpState.WaitingList]++
          break;
        case SignUpState.Confirmed:
          courseRegistrations[SignUpState.Confirmed]++
          break;
        case SignUpState.Payed:
          courseRegistrations[SignUpState.Payed]++
          break;
        case SignUpState.Cancelled:
          courseRegistrations[SignUpState.Cancelled]++
          break;
        default:
          break;
      }
    })

    return courseRegistrations
  }

  const getStatusTranslation = function (status: courseReg) {
    if (status.unconfirmed === 0 && status.waitingList === 0 && status.confirmed === 0 && status.payed === 0) {
      return <>
        <Tag kind={KIND.neutral} closeable={false} disabled>
          <FormattedMessage
            id={`courses.coursesTable.status.noRegistrations`}
            defaultMessage={'No registrations'}
          />
        </Tag>
        {status.cancelled > 0 && <Tag kind={KIND.yellow} closeable={false} disabled>
          <FormattedMessage
            id={`courses.coursesTable.status.cancelled`}
            defaultMessage={'cancelled'}
            values={{ count: status.cancelled }}
          />
        </Tag>}
      </>
    } else {
      return <>
        {status.unconfirmed > 0 && <Tag kind={KIND.neutral} closeable={false}>
          <FormattedMessage
            id={`courses.coursesTable.status.unconfirmed`}
            defaultMessage={'{count} unconfirmed'}
            values={{ count: status.unconfirmed }}
          />
        </Tag>}
        {status.waitingList > 0 && <Tag kind={KIND.yellow} closeable={false}>
          <FormattedMessage
            id={`courses.coursesTable.status.waitingList`}
            defaultMessage={'{count} on waiting list'}
            values={{ count: status.waitingList }}
          />
        </Tag>}
        {status.confirmed > 0 && <Tag kind={KIND.green} closeable={false}>
          <FormattedMessage
            id={`courses.coursesTable.status.confirmed`}
            defaultMessage={'{count} confirmed'}
            values={{ count: status.confirmed }}
          />
        </Tag>}
        {status.payed > 0 && <Tag kind={KIND.green} closeable={false} variant={VARIANT.solid}>
          <FormattedMessage
            id={`courses.coursesTable.status.payed`}
            defaultMessage={'{count} confirmed and payed'}
            values={{ count: status.payed }}
          />
        </Tag>}
        {status.cancelled > 0 && <Tag kind={KIND.neutral} closeable={false} disabled>
          <FormattedMessage
            id={`courses.coursesTable.status.cancelled`}
            defaultMessage={'cancelled'}
            values={{ count: status.cancelled }}
          />
        </Tag>}
      </>
    }
  }

  const getCourseStatusTranslation = function (status: string | undefined | null) {
    switch (status ? status : 'open') {
      case 'open':
        return (
          <Tag kind={KIND.neutral} closeable={false} disabled>
            <FormattedMessage
              id={`courses.coursesTable.courseStatus.open`}
              defaultMessage={'open'}
            />
          </Tag>
        )
      case 'confirmed':
        return (
          <Tag kind={KIND.green} closeable={false} variant={VARIANT.solid}>
            <FormattedMessage
              id={`courses.coursesTable.courseStatus.confirmed`}
              defaultMessage={'confirmed'}
            />
          </Tag>
        )
      case 'cancelled':
        return (
          <Tag kind={KIND.orange} closeable={false}>
            <FormattedMessage
              id={`courses.coursesTable.courseStatus.cancelled`}
              defaultMessage={'cancelled'}
            />
          </Tag>
        )
      default:
        return (
          <Tag kind={KIND.neutral} closeable={false} disabled>
            <FormattedMessage
              id={`courses.coursesTable.courseStatus.open`}
              defaultMessage={'open'}
            />
          </Tag>
        )
    }
  }

  useEffect(() => {
    setLoading(loadingGetCourses || loadingDelete)
  }, [loadingGetCourses, loadingDelete])

  useEffect(() => {
    if (errorGetCourses || errorDelete) {
      setAlert &&
        setAlert({
          title: intl.formatMessage({
            id: 'courses.coursesTable.error',
            defaultMessage: 'Error',
          }),
          label: intl.formatMessage({
            id: 'courses.coursesTable.networkError',
            defaultMessage: 'We had network problem. Please try again',
          }),
          style: 'error',
        })
      console.error(
        errorGetCourses?.message ||
          '' + errorDelete?.message
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorGetCourses, errorDelete])

  useEffect(() => {
    if (dataGetCourses && dataGetCourses?.getCourses.items.length > 0) {
      setData(
        dataGetCourses.getCourses.items.map((item) => ({
          id: item.id,
          title: getTranslation({
            de: item.title?.de || '',
            fr: item.title?.fr || '',
            it: item.title?.it || ''
          }, intl) || '',
          date:
            (item.date &&
              (new Date(item.date).toLocaleDateString('de-CH')) +
              (item.endDate ? ' - ' + new Date(item.endDate).toLocaleDateString('de-CH') : '')) ||
            '',
          createdFrom: item.createdFrom.toString(),
          status: getStatusTranslation(getCourseStatus(item)),
          hasOpenRegistration: getCourseStatus(item).unconfirmed > 0,
          hasWaitingList: getCourseStatus(item).waitingList > 0,
          hasMailableRegistration: getMailables(item) > 0,
          item: item,
          courseStatus: getCourseStatusTranslation(item.status),
          courseStatusValue: item.status||'open',
        }))
      )
      if (
        dataGetCourses.getCourses.nextCursor &&
        dataGetCourses.getCourses.nextCursor !== nextCursor
      ) {
        if (nextCursor !== dataGetCourses.getCourses.nextCursor) {
          setPageCount((prev) => ++prev)
        }
        setNextCursor(dataGetCourses.getCourses.nextCursor)
      }
    } else {
      setData([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGetCourses])


  function getFilter() {
    const filter: { id: string; value: string }[] = []

    if(personId) {
      filter.push({
        id: 'person_id',
        value: personId,
      })
    }
    return filter
  }

  return  loading ? (
    <Spinner $size={SIZE.large} />
  ) : (
    <CoursesTableBusiness
      goToCourse={goToCourse}
      data={data}
      onFetchMoreData={onFetchMoreData}
      onRefetchData={onRefetchData}
      loading={loading}
      pageCount={pageCount}
      pageSize={pageSize}
      setPageSize={setPageSize}
      deleteCourse={deleteCourse}
      sendMailing={sendMailing}
      confirmCourse={confirmCourse}
      cancelCourse={cancelCourse}
    />
  )
}
