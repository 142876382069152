const LocaleData = {
  'courses.courses': 'Weiterbildungen',

  'courses.overview': 'Übersicht',
  'courses.add': 'Hinzufügen',
  'courses.edit': 'Bearbeiten',
  'courses.registrations': 'Anmeldungen',
  'courses.registrationsFor': 'Anmeldungen für {courseTitle}',

  'courses.registrations.member': 'Mitglied',
  'courses.registrations.createdAt': 'Anmeldedatum',
  'courses.registrations.status': 'Status',
  'courses.registrations.invoice': 'Rechnung/Mailing',
  'courses.registrations.actions': 'Funktionen',
  'courses.registrations.status.payed': 'Bestätigt & Bezahlt',
  'courses.registrations.status.confirmed': 'Bestätigt',
  'courses.registrations.status.waitingList': 'Warteliste',
  'courses.registrations.status.unconfirmed': 'Offen',
  'courses.registrations.status.cancelled': 'Abgemeldet',
  'courses.registrations.status.reminded': 'Zahlungsverzug',
  'courses.registrations.status.remindedTitle': 'Zahlungserinnerung Versandt',
  'courses.registrations.confirm': 'Anmeldung bestätigen',
  'courses.registrations.noRegistrations': 'keine Anmeldungen',
  'courses.registrations.cancel': 'Abmelden',
  'courses.registrations.error': 'Fehler',
  'courses.registrations.saveComment': 'Kommentar Speichern',
  'courses.registrations.saveFinalPrice': 'Preis Speichern',
  'courses.registrations.networkError': 'Es gab ein Netzwerkproblem. Bitte versuchen Sie es erneut',
  'courses.registrations.saved': 'Gespeichert',
  'members.registrations.savedComment': 'Kommentar wurde gespeichert',
  'members.registrations.savedFinalPrice': 'Preis wurde gespeichert',

  'coursesAdd.courses_add': 'Hinzufügen',

  'courses.courses.title': 'Titel',
  "courses.courses.toOverview": 'Zur Übersicht',

  'courses.coursesEdit.toOverview': 'Zur Übersicht',
  'courses.courseEdit.error': 'Fehler',
  'courses.courseEdit.errorMessage': 'Es gab ein Netzwerkproblem. Bitte versuchen Sie es erneut',
  'courses.courseEdit.created': 'Erstellt',
  'courses.courseEdit.saved.created': 'Die Weiterbildung wurde erstellt',
  'courses.courseEdit.saved': 'Gespeichert',
  'courses.courseEdit.saved.updated': 'Die Weiterbildung wurde aktualisiert',
  'courses.coursesEdit.error.thereWasAServerError': 'Es gab einen Serverfehler. Bitte versuchen Sie es erneut',
  'courses.coursesEdit.error.notLoggedIn': 'Sie sind nicht mehr angemeldet. Die Weiterbildung wurde nicht gespeichert',

  'courses.coursesEdit.error.languageRequired': 'Sprache ist erfoderlich',
  'courses.coursesEdit.error.educationalStageRequired': 'Bildungsstufe ist erfordelich',
  'courses.coursesEdit.error.contentCategoryRequired': 'Inhaltskategorie ist erforderlich',
  'courses.coursesEdit.error.numberRequired': 'Nummer ist erforderlich',
  'courses.coursesEdit.error.titleRequired': 'Titel ist erforderlich',
  'courses.coursesEdit.error.descriptionRequired': 'Kurzbeschreibung ist erforderlich',
  'courses.coursesEdit.error.priceRequired': 'Kurskoten ist erforderlich',
  'courses.coursesEdit.error.memberPriceRequired': 'Kurskoten Mitglieder ist erforderlich',
  'courses.coursesEdit.error.teacherRequired': 'Kursleiter/in ist erforderlich',
  'courses.coursesEdit.error.targetGroupRequired': 'Zielgruppe ist erforderlich',
  'courses.coursesEdit.error.dateRequired': 'Datum ist erforderlich',
  'courses.coursesEdit.error.placeRequired': 'Ort ist erforderlich',
  'courses.coursesEdit.error.subscribeTillRequired': 'Anmelden bis ist erforderlich',
  'courses.coursesEdit.error.minSubscribersRequired': 'Min. Teilnehmer ist erforderlich',
  'courses.coursesEdit.error.maxSubscribersRequired': 'Max. Teilnehmer ist erforderlich',
  'courses.courseEdit.status': 'Status',
  'courses.courseEdit.selectStatus': 'Status auswählen',
  'courses.courseEdit.language': 'Sprache',
  'courses.courseEdit.selectLanguage': 'Sprache auswählen',
  'courses.courseEdit.educationalStage': 'Bildungsstufe',
  'courses.courseEdit.selectEducationalStage': 'Bildungsstufe auswählen',
  'courses.courseEdit.contentCategory': 'Inhaltskategorie',
  'courses.courseEdit.selectContentCategory': 'Inhaltskategorie auswählen',
  'courses.courseEdit.number': 'Nummer',
  'courses.courseEdit.title.de': 'Titel Deutsch',
  'courses.courseEdit.title.fr': 'Titel Französisch',
  'courses.courseEdit.title.it': 'Titel Italienisch',
  'courses.courseEdit.shortDescription.de': 'Kurzbeschreibung Deutsch',
  'courses.courseEdit.shortDescription.fr': 'Kurzbeschreibung Französisch',
  'courses.courseEdit.shortDescription.it': 'Kurzbeschreibung Italienisch',
  'courses.courseEdit.price': 'Kurskosten',
  'courses.courseEdit.memberPrice': 'Kurskosten Mitglieder',
  'courses.courseEdit.teacher': 'Kursleiter/in',
  'courses.courseEdit.targetGroup': 'Zielgruppe',
  'courses.courseEdit.special.de': 'Besonderes Deutsch',
  'courses.courseEdit.special.fr': 'Besonderes Französisch',
  'courses.courseEdit.special.it': 'Besonderes Italienisch',
  'courses.courseEdit.date': '(Start-)Datum',
  'courses.courseEdit.endDate': 'Enddatum',
  'courses.courseEdit.time': 'Zeit',
  'courses.courseEdit.place': 'Ort',
  'courses.courseEdit.subscribeTill': 'Anmelden bis',
  'courses.courseEdit.minSubscribers': 'Min. Teilnehmer',
  'courses.courseEdit.maxSubscribers': 'Max. Teilnehmer',
  'courses.courseEdit.options': 'Optionen',
  'courses.courseEdit.selectOption': 'Wähle eine Option',

  'courses.coursesTable.error': 'Fehler',
  'courses.coursesTable.networkError':
    'Es treten Netzwerkprobleme auf, bitte nochmals versuchen',
  'courses.coursesTable.course': 'Titel',
  'courses.coursesTable.courseActions': 'Kurs',
  'courses.coursesTable.date': 'Datum',
  'courses.coursesTable.endDate': 'Enddatum',
  'courses.coursesTable.registrations': 'Anmeldungen',
  'courses.coursesTable.courseStatus': 'Kursstatus',
  'courses.coursesTable.courseStatus.open': 'offen',
  'courses.coursesTable.courseStatus.confirmed': 'Bestätigt',
  'courses.coursesTable.courseStatus.cancelled': 'Abgesagt',
  'courses.coursesTable.status': 'Anmeldestatus',
  'courses.coursesTable.status.unconfirmed': ' {count} offen',
  'courses.coursesTable.status.waitingList': '{count} auf der Warteliste',
  'courses.coursesTable.status.confirmed': '{count} bestätigt',
  'courses.coursesTable.status.payed': '{count} bestätigt + bezahlt',
  'courses.coursesTable.status.noRegistrations': 'Keine Anmeldungen',
  'courses.coursesTable.status.confirmPending': 'offene Anmeldungen Bestätigen',
  'courses.coursesTable.status.confirmWaitingList': 'Warteliste Bestätigen',
  'courses.coursesTable.status.cancelled': '{count} abgemeldet',
  'courses.coursesTable.actions': 'Funktionen',
  'courses.coursesTable.delete': 'Löschen',
  'courses.coursesTable.deleteConfirm': 'Sicher, dass dieser Kurs gelöscht werden soll?',
  'courses.coursesTable.confirm': 'Bestätigen',
  'courses.coursesTable.confirmConfirm': 'Sicher, dass dieser Kurs bestätigt werden soll? Es werden alle offenen Anmeldungen bestätigt und entsprechend Rechnungen ausgelöst.',
  'courses.coursesTable.cancel': 'Absagen',
  'courses.coursesTable.cancelConfirm': 'Sicher, dass dieser Kurs abgesagt werden soll? Es werden Anmeldungen storniert.',
  'courses.coursesTable.loading': 'Laden...',
  'courses.coursesTable.results_count': 'Zeigt {pageLength} Resultate',
  'courses.coursesTable.load_mores': 'Mehr laden',
  'courses.coursesTable.page_count': 'Seite {currentPage}',
  'courses.coursesTable.page_size_selection': 'Zeige {pageSize} Elemente pro Seite',
  'courses.coursesTable.removeRow.success': 'Eintrag wurde erfolgreich gelöscht',

  'courses.addCourseRegistration.add': 'Anmeldung hinzufügen',
  'courses.addCourseRegistration.addWaitingList': 'Auf Warteliste setzen',
  'courses.addCourseRegister.error': 'Fehler',
  'courses.addCourseRegister.networkError': 'Es gab ein Netzwerkproblem. Bitte versuchen Sie es erneut',

  'courses.participantsExport.number': 'Kursnummer',
  'courses.participantsExport.title': 'Titel',
  'courses.participantsExport.salutation': 'Anrede',
  'courses.participantsExport.lastName': 'Nachname',
  'courses.participantsExport.firstName': 'Vorname',
  'courses.participantsExport.streetNr': 'Strasse Nr',
  'courses.participantsExport.postCode': 'PLZ',
  'courses.participantsExport.place': 'Ort',
  'courses.participantsExport.telephone': 'Telefon',
  'courses.participantsExport.mobile': 'Mobile',
  'courses.participantsExport.email': 'E-Mail',
  'courses.participantsExport.membership': 'Mitgliedschaft',
  'courses.participantsExport.signUpState': 'Anmeldestatus',
  'courses.participantsExport.ahvNumber': 'AHV Nummer',
  'courses.participantsExport.jsNumber': 'J+S Nummer',
  'courses.participantsExport.birthdate': 'Geburtsdatum',
  'courses.participantsExport.comment': 'Kommentar',
  'courses.participantsExport.error': 'Fehler',
  'courses.participantsExport.networkError': 'Es gab ein Netzwerkproblem. Bitte versuchen Sie es erneut',
  'courses.participantsExport.courseRegistrations': 'Kursanmeldungen exportieren',

  'courses.exportAttendeesList.error': 'Fehler',
  'courses.exportAttendeesList.networkError': 'Es gab ein Netzwerkproblem. Bitte versuchen Sie es erneut',
  'courses.exportAttendeesList.exportAttendeesList': 'Export Teilnehmerliste',
}

export default LocaleData
