import { Select } from "baseui/select";

export default function MemberSelector({
  isLoading,
  options,
  setValue,
  value,
  handleInputChange,
}: {
  isLoading: boolean;
  options?: { id: string; label: string }[];
  setValue: React.Dispatch<React.SetStateAction<{ id: string; label: string } | undefined>>
  value?: { id: string; label: string };
  handleInputChange: (value: string) => void;
}) {

  return (
    <Select
      isLoading={isLoading}
      options={options}
      onChange={({ value }) => parseInt(value[0].id?.toString() || '') > 0 && setValue(value[0] as { id: string; label: string })}
      onInputChange={(event: React.SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        handleInputChange(target.value);
      }}
      value={value ? [value] : undefined}
    />
  )
}
